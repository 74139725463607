import React, { useEffect, useState } from "react";
import "./MedicalCenterDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useSelector } from "react-redux";

const MedicalCenterDetails = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA9uTDVK8WhzhFAYQdqSQYaqrCzzOD5cuw",
  });

  // const history = useHistory();

  const location = useLocation();
  const navigate = useNavigate();

  const [hospitalLocation, setHospitalLocation] = useState();
  const [rangeValue, setRangeValue] = useState(location.state.hospital.price);
  const [distance, setDistance] = useState();
  const [hospital, setHospital] = useState(location.state.hospital);
  const userLocation = useSelector((state) => state.location.userLocation);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  useEffect(() => {
    setHospital(location.state.hospital);
  }, [location]);

  useEffect(() => {
    setRangeValue(hospital.price);
  }, [hospital]);

  useEffect(() => {
    const getDistance = () => {
      const service = new window.google.maps.DistanceMatrixService();
      const origin = new window.google.maps.LatLng(
        userLocation.lat,
        userLocation.lng
      );
      const destination = new window.google.maps.LatLng(
        hospitalLocation.lat,
        hospitalLocation.lng
      );

      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: "DRIVING",
          unitSystem: window.google.maps.UnitSystem.IMPERIAL,
        },
        (response, status) => {
          if (
            status === "OK" &&
            response.rows.length > 0 &&
            response.rows[0].elements.length > 0
          ) {
            const distanceValue = response.rows[0].elements[0].distance.value;
            setDistance(distanceValue / 1609.34); // convert meters to miles
          } else {
            setDistance(null);
            console.log("Error:", status);
          }
        }
      );
    };

    const getHospitalLocation = async () => {
      try {
        const results = await geocodeByAddress(hospital.name);
        const coordinates = await getLatLng(results[0]);
        setHospitalLocation(coordinates);
        getDistance();
      } catch (error) {
        console.log("Error", error);
      }
    };

    if (hospital.name) {
      getHospitalLocation();
    }
  }, [hospital, hospitalLocation]);

  const navigateBack = async () => {
    navigate(-1);
  };

  return (
    <div className="med-cen-container">
      <div className="med-cen-backIcon">
        <div onClick={navigateBack}>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            size="2x"
            style={{
              color: "white",
              border: "1px solid #585858",
              background: "#122853",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <div className="med-cen-cardsCont">
        <div className="title">
          <h2>{hospital.name}</h2>
          <p className="procedure-name">{hospital.procedure}</p>
          <span className="distance-away">
            {distance ? `${distance.toFixed(2)} miles away` : `Loading...`}
          </span>
        </div>
        <span className="map1">
          {isLoaded ? (
            <GoogleMap
              mapContainerClassName="map-container-det"
              center={hospitalLocation}
              zoom={17}
              options={{
                zoomControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
              }}
            >
              <Marker position={hospitalLocation} />
            </GoogleMap>
          ) : (
            <div className="loader-map">
              <div className="lds-ripple">
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </span>
      </div>
      <div className="med-cen-center">
        <h1> Prices Comparison</h1>
        <p> Based on our data you can pay around</p>
      </div>
      <div className="med-cen-bottom">
        <div className="med-cen-disc-price">
          <h1>{hospital.price}</h1>
          <p>Discounted cash price</p>
        </div>
        <div class="range-slider">
          <input
            type="range"
            min="6"
            max="28512"
            step="1"
            class="slider"
            id="myRange"
            value={rangeValue ? rangeValue : 500}
            // onChange={handleChange}
          />
          <div class="range-thumb">
            <span class="range-value range-value-left"></span>
            <span class="range-arrow">
              <span>{hospital.price}</span>
              <i class="fa-solid fa-caret-down"></i>
            </span>
            <span class="range-value range-value-right"></span>
          </div>
          <div className="range-left-right-val">
            <span className="range-left-val">$6</span>
            <span className="range-right-val">$28512</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalCenterDetails;
