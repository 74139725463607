import React from "react";
import "./PopHospCard.css";
import { useNavigate } from "react-router-dom";

const PopHospCard = ({ hospital }) => {
  const navigate = useNavigate();

  const handleClickNavigate = async () => {
    navigate(`/popular-hospitals/${hospital._id}`);
  };
  return (
    <div class="doctCard" key={hospital._id}>
      <div class="cardCont">
        <div class="img">
          <img src={`./hospital-images/${hospital.image}`} alt="" />
        </div>
        <div class="dec">
          <h1>{hospital.name}</h1>
          <div class="location">
            <h3>{hospital.location}</h3>
          </div>
          <a
            className="website"
            href={`${hospital.website}`}
            target="_blank"
            rel="noreferrer"
          >
            {hospital.website}
          </a>
          <p>
            {`${hospital.description.slice(0, 77)}...`}
            <span className="readMore" onClick={handleClickNavigate}>
              Read More
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PopHospCard;
