import React from "react";
import "./PageTItle.css";

const PageTItle = ({ title, desc }) => {
  return (
    <div className="page-title-container">
      <h1>{title}</h1>
      <p>{desc}</p>
    </div>
  );
};

export default PageTItle;
