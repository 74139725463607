import React, { useEffect, useState } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import Swipper from "../../components/Swiper/Swipper";
import Userswiper from '../../components/Userswiper/Userswiper';
import Searchbar from "../../components/Searchbar/Searchbar";
import { addLocation } from "../../redux/userLocation";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga";
import axios from "axios";

const Home = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const dispatch = useDispatch();
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });

  const getUserLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          console.log("Error getting user location");
        }
      );
    } else {
      console.log("Geolocation not supported");
    }
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  setTimeout(() => {
    dispatch(addLocation(userLocation));
  }, 5000);

  return (
    <div className="home">
      <section className="hero">
        {/* <!--==Content============================--> */}
        <div className="hero-content">
          {/* <!--**text****************--> */}
          <div className="hero-text">
            <h1>Feel Better About Finding Affordable Healthcare</h1>
            <p>
              Navigating the complex journey that is health care is exhausting.
              Health PlusCost bridges this complex gap in our healthcare system,
              and provides you with the transparency you deserve.{" "}
            </p>
            {/* <!--btns--> */}
            <div className="hero-text-btns">
              <Link to={"/popular-hospitals"}>
                <i className="fa-solid fa-magnifying-glass"></i> Popular
                Hospitals
              </Link>
              <Link to={"/hospitals"}>
                <i className="fa-solid fa-notes-medical"></i> See Pricing
              </Link>
            </div>
          </div>
          {/* <!--**img*****************--> */}
          <div className="hero-img">
            <img alt="" src="main-bg.png" />
          </div>
        </div>

        {/* <!--hero-end--> */}

        <div>
          <Searchbar />
        </div>

        {/* <!--==service-info===========================--> */}
        <section className="what-we-provide">
          {/* <!--**box1***********--> */}
          <div className="w-info-box w-i-box1">
            {/* <!--icon--> */}
            <div className="w-info-icon">
              <i className="fa-solid fa-capsules"></i>
            </div>
            {/* <!--text--> */}
            <div className="w-info-text">
              <strong>Specialised Service</strong>
              <p>Tailored services to meet your needs. </p>
            </div>
          </div>
          {/* <!--**box2***********--> */}
          <div className="w-info-box w-i-box2">
            {/* <!--icon--> */}
            <div className="w-info-icon">
              <i className="fa-regular fa-comments"></i>
            </div>
            {/* <!--text--> */}
            <div className="w-info-text">
              <strong>24/7 Transparency</strong>
              <p>We aim to offer the highest level of clarity. </p>
            </div>
          </div>
          {/* <!--**box3***********--> */}
          <div className="w-info-box w-i-box3">
            {/* <!--icon--> */}
            <div className="w-info-icon">
              <i className="fa-solid fa-square-poll-horizontal"></i>
            </div>
            {/* <!--text--> */}
            <div className="w-info-text">
              <strong>Peace Of Mind</strong>
              <p>We provide reassurance and comfort through transparency. </p>
            </div>
          </div>
        </section>
        {/* <!--end--> */}
      </section>

      {/* <!--==Story=============================--> */}
      <section id="our_story">
        {/* <!--**img**--> */}
        <div className="our-story-img">
    <img src="story_image1.jpg" alt="storyImage" />
    <a href="https://drive.google.com/file/d/1M1SRYheixa7XKiDb49TvujjXwFLfR5yA/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="story-play-btn">
        <i className="fa-solid fa-play"></i>
        Check us out!
    </a>
</div>

        {/* <!--**text**--> */}
        <div className="our-stroy-text">
          <h2>What We Do.</h2>
          <p>
          At Health PlusCost, we aim for transparency in healthcare pricing. 
          We've partnered with leading NYC healthcare providers to list prices 
          for common procedures like MRI scans and mammograms.
          </p>
          <p>
            Our platform is user-friendly and easy to navigate, enabling
            patients to access transparent pricing information in just a few
            clicks. By providing patients with upfront pricing information, we
            aim to reduce financial stress and improve access to healthcare,
            ultimately improving health outcomes for all. We
            believe that by working together, we can create a more equitable and
            accessible healthcare system for all.
          </p>
          {/* <!--numbers-of-happy-patients--> */}
          <div className="story-numbers-container">
            {/* <!--box--> */}
            <div className="story-numbers-box s-n-box1">
              <strong>1000+</strong>
              <span>Happy Patients</span>
            </div>
            {/* <!--box--> */}
            <div className="story-numbers-box s-n-box2">
              <strong>20+</strong>
              <span>Top Procedures</span>
            </div>
            {/* <!--box--> */}
            <div className="story-numbers-box s-n-box3">
              <strong>8</strong>
              <span>Popular NYC Insurances</span>
            </div>
            {/* <!--box--> */}
            <div className="story-numbers-box s-n-box4">
              <strong>1,000,000+</strong>
              <span>Data Points</span>
            </div>
          </div>
        </div>
      </section>
      {/* <!--story-end--> */}

      {/* <!--==Our-Services======================--> */}
      <section id="our-services">
        {/* <!--**heading********************--> */}
        <div className="services-heading">
          {/* <!--text--> */}
          <div className="services-heading-text">
            {/* <!-- <strong>Our Services</strong> --> */}
            <h2>Popular Searches</h2>
          </div>
          {/* <!--btns--> */}
          <div className="service-slide-btns">
            <div
              className="swiper-button-prev"
              // onClick={() => goToSlide(swiperRef.current - 1)}
            ></div>
            <div
              className="swiper-button-next"
              // onClick={() => goToSlide(swiperRef.current + 1)}
            ></div>
          </div>
        </div>

        {/* <!--**container*******************--> */}
        <div className="services-box-container">
          <Swipper />
        </div>

        {/* <!--btn--> */}
        <span className="service-btn">
          <Link to={"mailto:healthpluscost@gmail.com"}>Contact us</Link> for any
          questions or concerns{" "}
        </span>
      </section>
      {/* <!--services-end--> */}

      {/* <!--==Why-choose-us=======================--> */}
      <section id="why-choose-us">
        {/* <!--**left*****************--> */}
        <div className="why-choose-us-left">
          <h3>Why Choose Us?</h3>

          {/* <!--==container====--> */}
          <div className="why-choose-box-container">
            {/* <!--**box**--> */}
            <div className="why-choose-box">
              {/* <!--icon--> */}
              <i className="fa-solid fa-check"></i>

              {/* <!--text--> */}
              <div className="why-choose-box-text">
                <strong>Convenience</strong>
                <p>
                  Compare prices and find best options with ease on our
                  platform.
                </p>
              </div>
            </div>

            {/* <!--**box**--> */}
            <div className="why-choose-box">
              {/* <!--icon--> */}
              <i className="fa-solid fa-check"></i>

              {/* <!--text--> */}
              <div className="why-choose-box-text">
                <strong>Transparency</strong>
                <p>Upfront pricing for transparent healthcare costs.</p>
              </div>
            </div>

            {/* <!--**box**--> */}
            <div className="why-choose-box">
              {/* <!--icon--> */}
              <i className="fa-solid fa-check"></i>

              {/* <!--text--> */}
              <div className="why-choose-box-text">
                <strong>Predictability</strong>
                <p>Clear pricing for predictable healthcare costs.</p>
              </div>
            </div>

            {/* <!--**box**--> */}
            <div className="why-choose-box">
              {/* <!--icon--> */}
              <i className="fa-solid fa-check"></i>

              {/* <!--text--> */}
              <div className="why-choose-box-text">
                <strong>Awareness</strong>
                <p>
                  Stay informed about healthcare options with our resources.
                </p>
              </div>
            </div>
          </div>

          {/* <!--==btn========--> */}
          <Link to={"/hospitals"} className="why-choose-us-btn">
            See Pricing
          </Link>
        </div>
        {/* <!--left-end--> */}

        {/* <!--**right*******************--> */}
        <div className="why-choose-us-right">
          <h3>
            Need Help?
            <br />
            Contact Us
          </h3>
          <p>
            Thank you for your interest in Health PlusCost! If you have any
            questions or concerns about our platform or the services we provide,
            please don't hesitate to contact us. Our team is here to assist you
            in any way we can.
          </p>

          {/* <!--==container====--> */}
          <div className="w-right-contact-container">
            {/* <!--**box**--> */}
            {/* <div className="w-right-contact-box">

                          <i className="fa-solid fa-phone"></i>

            <div className="w-right-contact-box-text">
               <span>Call Us Now</span>
               <strong>+123 4567 89</strong>
             </div>
             </div> */}

            {/* <!--**box**--> */}
            <div className="w-right-contact-box">
              <i className="fa-solid fa-envelope"></i>

              {/* <!--text--> */}
              <div className="w-right-contact-box-text">
                <span>Mail Us</span>
                <strong>healthpluscost@gmail.com</strong>
              </div>
            </div>
          </div>
        </div>
        {/* <!--right-end--> */}
      </section>
      {/* <!--end--> */}

      {/* <!--==Testimonials============================--> */}
      <section id="testimonials">
  <div class="testimonials-heading">
    <h3>User Feedback</h3>
    <p>Check out what some of our users have to say about us!</p>
  </div>

  <div class="testimonials-content">
    <div class="testimonials-box-container">
      <div class="testimonials-box">
        <div class="t-profile">
          <div class="t-profile-img">
            <img alt="" src="t2.jpg" />
          </div>
          <div class="t-profile-text">
            <strong>John D.</strong>
            <span>Queens, NY</span>
            <div class="t-rating">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
          </div>
        </div>
        <p>
          Health PlusCost has been a game-changer for me. I was struggling
          with high medical bills and didn't know where to turn. With this
          platform, I was able to compare prices and find affordable options
          for my healthcare needs. The team behind Health PlusCost is
          incredibly helpful and responsive. I highly recommend their service
          to anyone looking to save money on medical expenses.
        </p>
      </div>

      <div class="testimonials-box">
        <div class="t-profile">
          <div class="t-profile-img">
            <img alt="" src="t2.jpg" />
          </div>
          <div class="t-profile-text">
            <strong>Emily W.</strong>
            <span>Queens, NY</span>
            <div class="t-rating">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
          </div>
        </div>
        <p>
          I can't say enough good things about Health PlusCost. The platform is
          easy to use, and the savings are significant. I was able to find a
          more affordable insurance plan and reduce my out-of-pocket expenses.
          It's a must-have tool for anyone looking to take control of their
          healthcare costs.
        </p>
      </div>


      <div class="testimonials-box">
        <div class="t-profile">
          <div class="t-profile-img">
            <img alt="" src="t2.jpg" />
          </div>
          <div class="t-profile-text">
            <strong>Samantha G.</strong>
            <span>Queens, NY</span>
            <div class="t-rating">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
          </div>
        </div>
        <p>
          I stumbled upon Health PlusCost when I was looking for a way to
          compare the costs of a medical procedure I needed. As someone who has
          always struggled with healthcare bills and unexpected expenses, I was
          blown away by how easy it was to use the platform and how much money
          I was able to save. Health PlusCost provided me with transparent
          pricing information for my procedure, and I was able to choose a
          provider and insurance plan that fit my budget. The platform is
          user-friendly, and the team is incredibly responsive and helpful. I've
          recommended Health PlusCost to all of my friends and family, and I
          truly believe that it's an essential tool for anyone navigating the
          complicated world of healthcare pricing. Thank you, Health PlusCost,
          for making healthcare more accessible and affordable for all.
        </p>
      </div>

      <div class="testimonials-box">
        <div class="t-profile">
          <div class="t-profile-img">
            <img alt="" src="t2.jpg" />
          </div>
          <div class="t-profile-text">
            <strong>Michael S.</strong>
            <span>Brooklyn, NY</span>
            <div class="t-rating">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
          </div>
        </div>
        <p>
          Health PlusCost has saved me a significant amount of money on my
          healthcare expenses. The platform is intuitive and provides accurate
          pricing information. I appreciate the transparency and the ability to
          compare different options. Thanks to Health PlusCost, I have more
          control over my healthcare spending and can make informed decisions.
        </p>
      </div>
    </div>
    </div>
</section>

      {/* <!--testimonials-end--> */}


      {/* <!--==Subscribe===========================--> */}
      <section id="subscribe">
  <h3>Let Us Know Where We Should Expand To!</h3>
  <form className="subscribe-box">
    <input type="email" placeholder="Example@gmail.com" />
    <button type="submit" onClick={() => window.location.href = "mailto:healthpluscost@gmail.com"} className="subscribe-btn">Subscribe</button>
  </form>
</section>


      {/* <!--end--> */}
    </div>
  );
};

export default Home;
