import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {Link} from 'react-router-dom'

const Swipper = () => {
  return (
    <div className="swiper mySwiperservices">
      <Swiper
        className="swiper-wrapper"
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={10}
        slidesPerView={1}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        breakpoints={{
          700: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        pagination={{ clickable: true }}
        scrollbar={{ hide: true }}
      >
        <SwiperSlide className="swiper-slide">
          {/* <!--**box1**--> */}
          <div className="service-box s-box1">
            {/* <!--icon--> */}
            <i className="fa-solid fa-tooth"></i>
            {/* <!--title--> */}
            <strong>MRI Scan</strong>
            {/* <!--details--> */}
            <p>
              MRI scans create detailed images of internal body structures using magnetic fields and radio waves.  
            </p>
            {/* <!--read more--> */}
          <Link to={"/hospitals"}>Search Now</Link>


          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          {/* <!--**box1**--> */}
          <div className="service-box s-box2">
            {/* <!--icon--> */}
            <i className="fa-solid fa-eye"></i>
            {/* <!--title--> */}
            <strong>Blood Test</strong>
            {/* <!--details--> */}
            <p>
              Blood tests analyze blood samples to detect diseases, infections, deficiencies, and monitor overall health.
            </p>
            {/* <!--read mor */}
            {/* e--> */}
            <Link to={"/hospitals"}>Seach Now</Link>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          {/* <!--**box1**--> */}
          <div className="service-box s-box3">
            {/* <!--icon--> */}
            <i className="fa-solid fa-face-smile"></i>

            {/* <!--title--> */}
            <strong>Appendectomy</strong>

            {/* <!--details--> */}
            <p>
              Surgical removal of the appendix, typically performed due to inflammation or infection.
            </p>

            {/* <!--read more--> */}
            <Link to={"/hospitals"}>Search Now</Link>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          {/* <!--**box1**--> */}
          <div className="service-box s-box4">
            {/* <!--icon--> */}
            <i className="fa-solid fa-user-doctor"></i>

            {/* <!--title--> */}
            <strong>PCR Nasal Swab</strong>

            {/* <!--details--> */}
            <p>
              A diagnostic test that detects genetic material of a virus, including SARS-CoV-2, from nasal secretions.
            </p>

            {/* <!--read more--> */}
            <Link to={"/hospitals"}>Search Now</Link>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Swipper;
