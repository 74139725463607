import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./HospitalDetails.css";

const HospitalDetails = () => {
  const [hospital, setHospital] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get(
        `https://health-plus-cost.herokuapp.com/api/v1/popular-hospitals/${id}`
      );
      setHospital(data.hospital);
    };
    getData();
  }, [id]);
  return (
    <div className="hosp-details-container">
      <div className="hosp-det-img">
        <img src={`/hospital-images/${hospital.image}`} alt="" />
      </div>
      <div className="hosp-det-content">
        <h1>{hospital.name}</h1>
        <p>{hospital.location}</p>
        <a href={`${hospital.website}`}>{hospital.website}</a>
        <p>{hospital.description}</p>
      </div>
    </div>
  );
};

export default HospitalDetails;
