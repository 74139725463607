import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import "./HospitalCard.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addProduct, removeFromCart } from "../../redux/cartRedux";

const HospitalCard = ({ hospital }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart.products);
  const user = useSelector((state) => state.user.currentUser);

  const isAddedToCart = cart.some((item) => item._id === hospital._id);

  const handleClick = (hospital) => {
    if (isAddedToCart) {
      dispatch(removeFromCart(hospital._id));
    } else {
      dispatch(addProduct(hospital));
    }
  };

  const navigateTo = () => {
    const encodedName = encodeURIComponent(hospital.name);
    navigate(`/hospitals-list/${encodedName}`, {
      state: { hospital },
    });
  };

  return (
    <div class="hospCard" key={hospital._id}>
      <div class="hostCont">
        <span className="map">
          <div style={{ width: "100%", height: "100%" }}>
            {!hospital.image ? (
              <div className="loader-map">
                <div className="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <img
                style={{ height: "300px" }}
                src={`${hospital.image}`}
                alt=""
              />
            )}
          </div>
        </span>
        <span onClick={() => navigateTo()}>
          <h1>{hospital.name}</h1>
          <h3>{hospital.price}</h3>
        </span>
        <div
          class="locHeart"
          onClick={(e) => {
            user ? handleClick(hospital) : navigate("/sign-in");
          }}
        >
          <FontAwesomeIcon
            color={isAddedToCart ? "red" : "gray"}
            style={{ cursor: "pointer" }}
            icon={faHeart}
            className="heart-icon"
          />
        </div>
      </div>
    </div>
  );
};

export default HospitalCard;
