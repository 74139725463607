import React, { useEffect, useState } from "react";
import "./PopularHospitals.css";
import PopHospCard from "../../components/PopHospCard/PopHospCard";
import axios from "axios";

const PopularHospitals = () => {
  const [hospitals, setHospitals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getHospitals = async () => {
    try {
      const { data } = await axios.get(
        "https://health-plus-cost.herokuapp.com/api/v1/popular-hospitals"
      );
      setHospitals(data.popularHospitals);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getHospitals();
  });
  return (
    <div>
      <div className="doctors">
        {!isLoading ? (
          hospitals.map((hospital) => <PopHospCard hospital={hospital} />)
        ) : (
          <div>
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PopularHospitals;
