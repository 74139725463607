import React from "react";
import PageTItle from "../../components/PageTitle/PageTItle";
import "./privacypolicy.css";

const PrivacyPolicy = () => {
  return (
    <div>
      <PageTItle
        title={"Privacy Policy"}
        desc={"Last Updated: Jan 10, 2023"}
      />
      <div className="privacy">
        <p>Support {">"} Privacy Policy</p>
        <div className="section1 section">
          <h1>Privacy Policy</h1>
          <p>
            HealthPlusCost Inc. (“us”, “we”, or “our”) operates the
            https://HealthPlusCost.com website (the “Service”).
          <br></br>
            This page informs you of our policies regarding the collection, use
            and disclosure of Personal Information when you use our Service.
      <br></br>
            We will not use or share your information with anyone except as
            described in this Privacy Policy.
       <br></br>
            We use your Personal Information for providing and improving the
            Service. By using the Service, you agree to the collection and use
            of information in accordance with this policy. Unless otherwise
            defined in this Privacy Policy, terms used in this Privacy Policy
            have the same meanings as in our Terms and Conditions, accessible at
            https://HealthPlusCost.com.
          </p>
        </div>
        <div className="section2 section">
          <h1>Information Collection And Use</h1>
          <h2>a. Collection of Personal Information</h2>
          <p>
          “Personal Information” means all personally-identifiable information (such as e.g., your name, physical address, e-mail address, home and mobile telephone numbers, or birth date) that you submit in connection with the Services. Health PlusCost does not collect Personal Information unless you voluntarily submit it in connection with your use of the Services, send inquiries to Health PlusCost, or participate in certain activities available via the Services. We collect Personal Information when you choose to use certain features of the Services, including when you: (i) create an account on the Website or the App; (ii) engage in certain activities available via the Services, such as uploading content including health insurance information; or (iii) consent to receive commercial or promotional email, direct mail, or SMS/text messages about upcoming promotions or events.
          <br></br>
          <br></br>
          We may collect personal information about consumers that is considered “protected health information” (“PHI”) that we receive directly from you. PHI is governed by the privacy, security, and breach notification provisions of federal laws known as HIPAA (the Health Insurance Portability and Accountability Act of 1996, Public Law 104-191) and HITECH (the Health Information Technology for Economic and Clinical Health Act, Public Law 111-5), and their implementing regulations (Parts 160 and 164 of the Code of Federal Regulations). State law may provide additional requirements for PHI. This Privacy Policy does not cover PHI we collect about you because we are not a healthcare provider, or otherwise a covered entity or business associate subject to HIPAA. If you want to know how your healthcare providers and their business associates safeguard, use, and disclose PHI about you, ask your healthcare provider for its “Notice of Privacy Practices.”
          </p>
          <p>
            We collect this information for the purpose of providing the
            Service, identifying and communicating with you, responding to your
            requests/inquiries, servicing your purchase orders, and improving
            our services.
          </p>
          <h2>b. Information Gathered Automatically</h2>
          <p>Health PlusCost automatically collects certain non-identifying information about you (such as your movement about the Website or App, the pages you visit most frequently, the type of browser or computer operating system you use, or the domain name of the website from which you link).
<br></br>
<br></br>
In addition, Health PlusCost may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile devices’ unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile internet browsers you use, and the information about the way you use the Services.
<br></br>
<br></br>
Health PlusCost uses “cookies” to customize and improve your use of the Services. Cookies are small pieces of information or data stored on your computer or mobile device that are used to determine your preferences and track certain behavior or activities when you use the Services. If you object to this practice, you may erase or block cookies. We recommend, however, that you allow cookies to be stored on your computer or mobile device as they may be needed to properly use the Services.
</p>

    <h2>c. Information on Location Data</h2>
    <p>
    When you use our Services, we may use GPS technology (or other similar technology) to determine your current location in order to determine clinics, hospitals, and other healthcare providers near you and provide you the relevant information and advertisements. Based on your user setting, we may share your current location with other users or partners to customize your Services experience and view more relevant information.
If you do not want us to use your location for the purposes set forth above, you should turn off the location services for the mobile application located in your account settings or in your mobile phone settings and/or within the mobile application. Please note, if you turn off the location services, some functions of the Services will be unavailable.
    </p>
        </div>
        <div className="section3 section">
          <h1>Use or Disclosure of Personal Information</h1>
          <p>
          Health PlusCost may use your Personal Information to contact you regarding the Services. We may also use your Personal Information to provide services requested by you and for internal research and marketing purposes. We may share your Personal Information and non-identifying information that we collect from you with our parent, subsidiaries, divisions, or affiliates.
<br></br>
<br></br>
Health PlusCost will not use, disclose, or sell your Personal information to any third parties for any purposes other than those necessary to deliver the Services. For example, it is necessary for us to provide information on location data to healthcare providers in order to determine clinics, hospitals, and other healthcare providers near you and provide you the relevant information and advertisements. Otherwise, we will not sell or transfer to unaffiliated third parties any Personal Information that you submit or any non-identifying information collected without your consent. The Personal Information and non-identifying information we collect from you may be used as follows:
<br></br>
<br></br>
i. to present our Services and its contents to you, including to personalize your experience and better respond to your individual needs;
<br></br>
<br></br>
ii. to improve the Services;
<br></br>
<br></br>
iii. to improve customer service;
<br></br>
<br></br>
iv. to administer contests, promotions, surveys or other features for the Services;
<br></br>
<br></br>
v. to send periodic mailings concerning information and updates pertaining to Health PlusCost;
<br></br>
<br></br>
vi. to send special or promotional offers or discounts related to Health PlusCost or its affiliates and information related to products you have viewed via the Services;
<br></br>
<br></br>
vii. to provide you with information, products, or services that you request from us;
<br></br>
<br></br>
viii. to help maintain the safety, security, and integrity of our Services, databases and other technology assets and business; or
<br></br>
<br></br>
ix. as an asset in connection with a proposed or actual merger or sale (including any transfers made as part of an insolvency or bankruptcy proceeding) involving all or part of our business or as part of a corporate reorganization, stock sale or other change in control.
<br></br>
<br></br>
Notwithstanding the foregoing or any other statements or representations here or elsewhere, Health PlusCost reserves the right to disclose any Personal Information in our possession if Health PlusCost believes that such disclosure is necessary: (i) to conform to legal requirements or to respond to a subpoena, search warrant, or other legal process received by Health PlusCost, whether or not a response is required by applicable law; (ii) to enforce or protect Health PlusCost’s rights; or (iii) to protect the safety of users of Health PlusCost’s Services and members of the general public.
<br></br>
<br></br>
WE ARE NOT RESPONSIBLE FOR THE PERSONAL INFORMATION AND OTHER NON-IDENTIFYING INFORMATION YOU CHOOSE TO SUBMIT IN ANY PUBLICLY ACCESSIBLE AREA OF THE SERVICES.
          </p>
        </div>
        <div className="section4 section">
          <h1>Security</h1>
          <p>
          Health PlusCost has reasonable security measures in place to protect Personal Information you submit in order to receive the Services. However, the transmission of information via the internet is not, and cannot be made, completely secure. We cannot and do not guarantee, ensure, or warrant that personal information will be completely secure from misappropriation by hackers or from other bad actors or criminals, or from the failure of computer hardware, software, or telecommunications networks. Health PlusCost assumes no responsibility for the security of any Personal Information you submit. We will notify you, as required by law, if we discover a security breach involving your Personal Information (as defined by the applicable state and federal laws) processed by us.
          </p>
        </div>
        <div className="section2 section">
          <h1>Links</h1>
          <p>
         We may permit certain third parties to link to our Services, and the Services may contain links to third-party sites. Health PlusCost is not responsible for the privacy policies or practices of such third parties, including on such third parties’ websites. When linking to a third party website, you should read the privacy policy stated on that website. This Privacy Policy only governs Personal Information, non-identifying information and content collected in connection with the Services.
</p>
</div>
<div className="section2 section">
          <h1>Additional Protections for Children</h1>
          <p>
The Services are not designed for or targeted to children under the age of 13, and Health PlusCost will never knowingly solicit or collect the Personal Information of a child under the age of 13. If we discover that Personal Information of a child under the age of 13 has been submitted without verifiable parental consent, we will immediately delete such Personal Information.
</p>
</div>
<div className="section2 section">
          <h1>Your California Privacy Rights</h1>
          <p>
If you are a California resident, California law may provide you with additional rights regarding our use of your personal information, including the right to request businesses to tell you how your personal information has been shared with third parties, if at all, for the third parties’ direct marketing purposes. To make such a request, please contact us at the contact information listed below.
</p>
</div>

<div className="section2 section">
          <h1>Contact</h1>
          <p>
If you have any questions about this Privacy Policy or your dealings with Health PlusCost, please feel free to contact us at: healthpluscost@gmail.com
</p>
</div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
