import React, { useEffect, useRef } from "react";
import "./Billing.css";

const Step = ({ image, title, description, isImageOnLeft }) => {
  return (
    <div className="billing-step-wrapper">
      <div className={`billing-step ${!isImageOnLeft ? "billing-step-reverse" : ""}`}>
        <div className="billing-step-image">
          <img className="step-image" src={image} alt={title} />
        </div>
        <div className="billing-step-content">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

const Billing = () => {
  const benefitsListRef = useRef(null);

  useEffect(() => {
    const checkIfInView = () => {
      const rect = benefitsListRef.current.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        benefitsListRef.current.classList.add("in-view");
      }
    };

    window.addEventListener("scroll", checkIfInView);
    checkIfInView();  // initial check in case the element is already in view

    return () => {
      window.removeEventListener("scroll", checkIfInView);
    };
  }, []);

  return (
    <div className="billing-container">
      <div className="billing-header">
        <div className="billing-header-content">
          <h1 className="billing-title">
            <span className="billing-cash"><strong>CASH</strong></span>: Cost Adjustment Service for Healthcare
          </h1>
          <p className="billing-description">
            We negotiate your hospital bill for you, you pay nothing unless we save you money.
          </p>
          <a href="https://form.jotform.com/231578142829160" target="_blank" rel="noopener noreferrer" className="billing-button">
          Start Saving Now
        </a>
        </div>
        <img className="billing-header-image" src="CASHLogo.png" alt="Header Image" />
      </div>

      <p className="billing-statistic">
        Did you know that at least 80% of medical bills are coded incorrectly? This startling statistic highlights the widespread issue of inflated medical expenses. Americans already spend an average of $12,530 on medical expenses every year, accounting for nearly 20% of their annual earnings. For those earning the median household income of $67,521, these costs can be especially burdensome (according to 2020 U.S. Census Bureau data). We believe that it's time to challenge the status quo and provide a solution that empowers individuals to reclaim their financial well-being.
      </p>

      <div className="billing-section-title">
        <p>
          How <span className="billing-navy">CASH</span> Works
        </p>
      </div>

      <div className="billing-steps-section">

        <div className="billing-steps-container">
          <Step
            image="step1-2.png"
            title="Step 1: Answer a Few Questions."
            description="After you give us your approval, trained professionals reach out to the hospital directly to receive a detailed version of your bill."
            isImageOnLeft={true}
          />

          <Step
            image="step2-2.png"
            title="Step 2: We Negotiate Your Bill."
            description="Medical billing specialists meticulously examine your bill to identify any questionable charges and negotiate on your behalf."
            isImageOnLeft={false}
          />

          <Step
            image="step3-2.png"
            title="Step 3: Your Medical Bill is Lowered!"
            description="CASH is 100% free unless we save you money. We only charge 8% of the amount of money we save for you - which pushes us to save you even more."
            isImageOnLeft={true}
          />
        </div>
      </div>

      <div className="billing-section-title">
  <p>Key Benefits of <span className="billing-navy">CASH</span></p>
</div>

      <div className="billing-images">
        <img className="billing-left-image" src="billing-image1.png" alt="Image 1" />
        <div className="billing-benefits-text">
        <h3 className="billing-benefits-heading">100% free unless we save you money.</h3>
        <ul className="billing-benefits-list" ref={benefitsListRef}>
            <li className="billing-benefit"><span><strong>Significant Cost Reduction</strong></span></li>
            <li className="billing-benefit"><span><strong>No Upfront Fee</strong></span></li>
            <li className="billing-benefit"><span><strong>Expert Negotiation</strong></span></li>
            <li className="billing-benefit"><span><strong>Transparent and Affordable</strong></span></li>
            <li className="billing-benefit"><span><strong>Empowering Financial Freedom</strong></span></li>
        </ul>
      </div>
      </div>

<div class="ending">
      <div className="billing-section-title">
  <p>Try <span className="billing-navy">CASH</span> for free</p>
</div>
      <a href="https://form.jotform.com/231578142829160" target="_blank" rel="noopener noreferrer" className="billing-button">
          Start Saving Now
        </a>

    </div>
    </div>
  );
};

export default Billing;
