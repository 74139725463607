import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import PageTItle from "../../components/PageTitle/PageTItle";
import "./disclaimer.css";

const disclaimer = () => {
  return (
    <>
      <PageTItle
        title={"Disclaimer"}
        desc={"Effective date: 10th of February, 2023"}
      />
      <div class="TermsConditions">
        <p>Support {">"} Disclaimer </p>
        <div class="termSec">
          <h1>Acknowledgement</h1>
          <p>
          The prices displayed on this website are for informational purposes only and may not be accurate or up to date. It is important to note that the pricing information provided is based on public data and is subject to change without notice. Therefore, we cannot guarantee the accuracy, completeness, or reliability of the information displayed.

We strongly recommend that users contact the hospitals directly to inquire about the specific pricing details for their desired procedures, zip codes, and insurance coverage. The hospital's billing department or financial services can provide the most accurate and current pricing information.

By using this website and relying on the information presented, users acknowledge that the pricing data may not reflect the actual costs and should exercise due diligence in verifying the details with the hospitals directly.
          </p>
        </div>
        <div class="termSubSec">
          <h1>Terms</h1>
          <div class="contents">
            <div class="content">
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "24px", color: "blue", marginRight: "10px" }}
              />
              <p>
              The information provided on this website is intended for general informational purposes only. While we strive to provide accurate and up-to-date information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
              </p>
            </div>
            <div class="content">
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "24px", color: "blue", marginRight: "10px" }}
              />
              <p>
              The pricing information displayed on this website is based on publicly available data and may not reflect the actual prices charged by the hospitals or medical facilities. The displayed prices should be used for general reference purposes only and should not be considered as binding offers or guarantees. The actual costs may vary depending on factors such as insurance coverage, specific procedures, geographic location, and other individual factors.
              </p>
            </div>
            <div class="content">
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "24px", color: "blue", marginRight: "10px" }}
              />
              <p>
              We recommend contacting the hospitals or medical facilities directly to obtain the most accurate and up-to-date pricing information. It is advisable to consult with healthcare professionals or financial advisors regarding your specific healthcare needs and financial situations.
              </p>
            </div>
            <div class="content">
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "24px", color: "blue", marginRight: "10px" }}
              />
              <p>
              By using this website, you agree that the pricing information provided may not be accurate or up to date. You acknowledge that the displayed prices are for informational purposes only and should not be solely relied upon for making healthcare-related decisions. It is your responsibility to verify the pricing details with the hospitals or medical facilities before making any commitments or decisions.
              </p>
            </div>
            <div class="content">
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "24px", color: "blue", marginRight: "10px" }}
              />
              <p>
              In no event will we be liable for any loss or damage including, without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default disclaimer;
