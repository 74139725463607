import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginFailure, loginStart, loginSuccess } from "../../redux/userRedux";
import "./style.css";

const Signin = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const navigate = useNavigate();

  const handleClick = async (e) => {
    e.preventDefault();
    dispatch(loginStart());
    try {
      const res = await axios.post(
        "https://health-plus-cost.herokuapp.com/api/v1/signin", 
        { email, password }
      );
      dispatch(loginSuccess(res.data));
      navigate(-1);
    } catch (err) {
      setEmail("");
      setPassword("");
      setError(err.response.data.error);
      dispatch(loginFailure());
    }
  };

  return (
    <div class="container">
      <div class="forms">
        <div class="form login">
          <span class="title">Login</span>
          <form action="#">
            <div class="input-field">
              <input
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                value={email}
              />
              <i class="uil uil-envelope icon"></i>
            </div>
            <div class="input-field">
              <input
                type="password"
                class="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
                value={password}
              />
              <i class="uil uil-lock icon"></i>
              <i class="uil uil-eye-slash showHidePw"></i>
            </div>

            <p className="error-messsage">{error ? error : ""}</p>

            <div class="checkbox-text">
              <div class="checkbox-content">
                <input type="checkbox" id="logCheck" />
                <label for="logCheck" class="text">
                  Remember me
                </label>
              </div>

              <Link to={"#"} class="text">
                Forgot password?
              </Link>
            </div>

            <div class="input-field button">
              <input type="button" value="Login" onClick={handleClick} />
            </div>
          </form>

          <div class="login-signup">
            <span class="text">
              Not a member?
              <Link to={"/sign-up"} class="text signup-link">
                Sign Up Now
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
