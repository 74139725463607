import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer>
      <div className="footer-outer-container">
        <div className="footer-container">
          {/* <!--**comoany-box**--> */}
          <div className="footer-company-box">
            {/* <!--logo--> */}
            <Link to={"/"} onClick={scrollToTop} className="logo">
              <span>Health</span>PlusCost
            </Link>
            {/* <!--details--> */}
            <p>
              Transparency in healthcare pricing, at your fingertips.
            </p>
            {/* <!--social-box--> */}
            <div className="footer-social">

              <Link to={"https://www.instagram.com/healthpluscost/"} className="footer-social-link">
                <i className="fa-brands fa-instagram"></i>
              </Link>

              <Link to={"#"} className="footer-social-link">
                <i className="fa-brands fa-tiktok"></i>
              </Link>
            </div>
          </div>
          {/* <!--**link-box***--> */}
          <div className="footer-link-box">
            <strong>Company</strong>
            <div>
              <li>
                <Link to={"/"} onClick={scrollToTop}>
                  Home
                </Link>
              </li>
              <li>
                <Link to={"/About"} onClick={scrollToTop}>
                  About
                </Link>
              </li>
              <li>
                <Link to={"#"} onClick={scrollToTop}>
                  Contact
                </Link>
              </li>
              <li>

              </li>
              <li>

              </li>
            </div>
          </div>
          {/* <!--**link-box***--> */}
          <div className="footer-link-box">
            <strong>Support</strong>
            <div>
              <li>

              </li>
              <li>
                <Link to={"/privacy-and-policy"} onClick={scrollToTop}>
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to={"/disclaimer"} onClick={scrollToTop}>
                  Disclaimer
                </Link>
              </li>
              <li>
                <Link to={"/terms-and-conditions"} onClick={scrollToTop}>
                  Terms and Conditions
                </Link>
              </li>
              <li>
                <Link to={"/frequently-asked-questions"} onClick={scrollToTop}>
                  FAQs
                </Link>
              </li>
            </div>
          </div>
          {/* <!--**link-box***--> */}
          <div className="footer-link-box">
            <strong>Get In Touch</strong>
            <div>
              <li>Email: healthpluscost@gmail.com</li>
            </div>
          </div>
        </div>

        <span className="footer-line"></span>
        <p className="footer-bottom-content">
          &copy; 2023 Health PlusCost. All Rights Reserved
        </p>
      </div>
      {/* <!--**bottom**********************--> */}
    </footer>
  );
};

export default Footer;
