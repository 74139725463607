import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        "https://health-plus-cost.herokuapp.com/api/v1/signup",
        {
          name,
          email,
          password,
        }
      );
      window.location.replace("/sign-in");
    } catch (error) {
      setName("");
      setEmail("");
      setPassword("");
      setError(error.response.data.error);
    }
  };

  return (
    <div class="container">
      <div class="forms">
        <div class="form ">
          <span class="title">Create an Account</span>

          <form action="#">
            <div class="input-field">
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
                required
                value={name}
              />
              <i class="uil uil-user"></i>
            </div>
            <div class="input-field">
              <input
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                value={email}
              />
              <i class="uil uil-envelope icon"></i>
            </div>
            <div class="input-field">
              <input
                type="password"
                class="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Create a password"
                required
                value={password}
              />
              <i class="uil uil-lock icon"></i>
            </div>
            <p className="error-messsage">{error ? error : ""}</p>
            <div class="checkbox-text">
              <div class="checkbox-content">
                <input type="checkbox" id="termCon" />
                <label for="termCon" class="text">
                  I accept all <Link>terms and conditions</Link>
                </label>
              </div>
            </div>

            <div class="input-field button">
              <input type="submit" onClick={handleSubmit} value="Signup" />
            </div>
          </form>

          <div class="login-signup">
            <span class="text">
              Already a member?
              <Link to={"/sign-in"} class="text login-link">
                Login Now
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
