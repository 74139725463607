import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "location",
  initialState: {
    userLocation: null,
  },
  reducers: {
    addLocation: (state, action) => {
      state.userLocation = action.payload;
    },
    // 
  },
});

export const { addLocation } = locationSlice.actions;
export default locationSlice.reducer;
