import React, { useEffect, useRef, useState } from "react";
import "./User.css";
import "../Hospitals/hospitals.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { updateUser } from "../../redux/userRedux";
import HospitalCard from "../../components/HospCard/HospitalCard";

const User = () => {
  const { user } = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const id = user._id;
  const [avatar, setAvatar] = useState();

  useEffect(() => {
    if (avatar) {
      axios
        .post(
          "https://health-plus-cost.herokuapp.com/api/v1/updateUser",
          { id, avatar },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          dispatch(updateUser(result.data.secure_url));
        })
        .catch((err) => console.log(err));
    }
  }, [avatar]);

  const updateProfileDataChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const FavHospitals = useSelector((state) => state.cart.products);

  const fileInput = useRef(null);

  const handleClickFile = () => {
    fileInput.current.click();
  };

  return (
    <div className="user-container">
      <div className="user-photo-name">
        <div onClick={handleClickFile}>
          <img src={user.image} alt="avatar" />
          <input
            type="file"
            hidden
            id="file"
            ref={fileInput}
            onChange={updateProfileDataChange}
            src=""
          />
        </div>
        <div>
          <h1>{user.name}</h1>
          <p>{user.email}</p>
        </div>
      </div>
      <div className="user-cards-container">
        {FavHospitals.map((favHop) => (
          <>
            <HospitalCard hospital={favHop} />
          </>
        ))}
      </div>
    </div>
  );
};

export default User;
