/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HospitalCard from "../../components/HospCard/HospitalCard";
import Searchbar from "../../components/Searchbar/Searchbar";
import "./hospitals.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { Link } from "react-router-dom";

const Hospitals = () => {
  const [hospitals, setHospitals] = useState([]);
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const insurance = queryParams.insurance;
  const procedure = queryParams.procedure;
  const zipCode = queryParams.zipCode;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        let url = "https://health-plus-cost.herokuapp.com/api/v1/hospitals?";
        if (procedure) {
          procedure.trim();
          url += `procedure=${procedure}&`;
        }
        if (insurance) {
          insurance.trim();
          url += `insurance=${insurance}&`;
        }
        if (zipCode) {
          zipCode.trim();
          url += `zipCode=${zipCode}&`;
        }
        url = url.slice(0, -1);
        const { data } = await axios.get(url);
        setHospitals(data.hospitals);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, [procedure, insurance, zipCode]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  return (
    <div>
      <Searchbar />
      <div className="hospitals">
        <div className="topProcedure">
          <h1>Compare Prices for Healthcare Services</h1>
          <p>
          To access pricing information for your desired medical procedure, simply enter 
          the procedure name, your zip code, and insurance information into our user-friendly platform. Our system 
          then provides transparent pricing information for the top 20 procedures and top 8 insurances across the NYC area. 
          We display a range of costs and utilize the Google Maps API to provide location information for each provider. If 
          you're logged in to your account, you'll also have the option to "heart" a specific result and save it to your profile for 
          easy access later. With Health PlusCost, you can easily compare pricing information for various providers and find the best 
         healthcare options to fit your needs and budget.
          </p>
          <p class="disclaimer">
          (1) The numbers provided are estimates calculated from public information, including data published by the providers in accordance with the <a href="https://www.cms.gov/hospital-price-transparency">Hospital Price Transparency Rule</a>.<br></br>
          <br></br>
          (2) The numbers are only <strong>estimates</strong> and are <strong>not guaranteed</strong> unless explicitly marked. Discrepancies may vary. Please check out our <Link to="/Disclaimer">disclaimer</Link> for more information.
          </p>
        </div>
        <div className="hospital-cards-container">
          {!isLoading ? (
            hospitals.map((hospital) => <HospitalCard hospital={hospital} />)
          ) : (
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hospitals;
