import React, { useState } from "react";
import "./Searchbar.css";
import { useNavigate } from "react-router-dom";
import { ZipCodes, Procedures, Insurances } from "./SearchPred";

const Searchbar = () => {
  const [procedure, setProcedure] = useState("");
  const [insurance, setInsurance] = useState("");
  const [zipCode, setZipCode] = useState("");

  const navigate = useNavigate();

  const handleCLick = (e) => {
    e.preventDefault();
    if (procedure.trim() || insurance.trim() || zipCode.trim()) {
      let url = "/hospitals?";
      if (procedure) {
        procedure.trim();
        url += `procedure=${procedure}&`;
      }
      if (insurance) {
        insurance.trim();
        url += `insurance=${insurance}&`;
      }
      if (zipCode) {
        zipCode.trim();
        url += `zipCode=${zipCode}&`;
      }
      url = url.slice(0, -1);
      navigate(url);
    } else {
      navigate("/hospitals");
    }
  };

  return (
    <div className="appointment-search-container">
      <h3>Search Result</h3>
      <div className="appointment-search">
        <div className="appo-search-box">
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            onChange={(e) => {
              setProcedure(e.target.value.toLowerCase());
            }}
            type="text"
            placeholder="Search Procedure"
            value={procedure}
          />

          <ul className="predicted-list">
            {procedure.length !== 0 ? (
              Procedures.filter((pro) =>
                pro.procedure.toLocaleLowerCase().includes(procedure)
              )
                .slice(0, 7)
                .map((proc) => (
                  <li
                    className="listItem"
                    onClick={() => setProcedure(proc.procedure)}
                  >
                    {proc.procedure}
                  </li>
                ))
            ) : (
              <></>
            )}
          </ul>
        </div>
        <div className="appo-search-box">
          <i className="fa-solid fa-location-dot"></i>
          <input
            onChange={(e) => {
              setZipCode(e.target.value);
            }}
            type="text"
            placeholder="Zip Code"
            value={zipCode}
          />

          <ul
            className="predicted-list"
            style={
              zipCode.length === 5 ? { display: "none" } : { display: "block" }
            }
          >
            {zipCode.length !== 0 ? (
              ZipCodes.filter((zip) => zip.zipCode.includes(zipCode))
                .slice(0, 7)
                .map((zipc) => (
                  <li
                    className="listItem"
                    onClick={() => {
                      setZipCode(zipc.zipCode);
                    }}
                  >
                    {zipc.zipCode}
                  </li>
                ))
            ) : (
              <></>
            )}
          </ul>
        </div>
        <div className="appo-search-box">
          <i className="fa-solid fa-location-dot"></i>
          <input
            onChange={(e) => {
              setInsurance(e.target.value.toLowerCase());
            }}
            type="text"
            placeholder="Insurance"
            value={insurance}
          />

          <ul className="predicted-list">
            {insurance.length !== 0 ? (
              Insurances.filter((ins) =>
                ins.insurance.toLocaleLowerCase().includes(insurance)
              )
                .slice(0, 7)
                .map((insu) => (
                  <li
                    className="listItem"
                    onClick={() => setInsurance(insu.insurance)}
                  >
                    {insu.insurance}
                  </li>
                ))
            ) : (
              <></>
            )}
          </ul>
        </div>
        <button className="searchBtn" onClick={(e) => handleCLick(e)}>
          <i className="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
  );
};

export default Searchbar;
