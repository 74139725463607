import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import PageTItle from "../../components/PageTitle/PageTItle";
import "./TermsConditions.css";

const TermsConditions = () => {
  return (
    <>
      <PageTItle
        title={"Terms & Condition"}
        // desc={"Effective date: 10th of February, 2023"}
      />
      <div class="TermsConditions">
        <p>Help Center {">"} Terms & Condition </p>
        <div class="termSec">
          <h1>Terms of Use</h1>
          <p>
          Health PlusCost, Inc. (“Health PlusCost”) and its affiliates and subsidiaries (collectively, “Health PlusCost,” “we,” “us,” or “our”) own all right, title, and interest in and to the websites located at HealthPlusCost.com (collectively, the “Website”) and the Health PlusCost mobile application (the “App,” and collectively with the Website, the “Services”). We welcome you to use our Services and wish to inform you about some important points to consider while using the Services. We have established the following terms and conditions (the “Agreement”) with which you must comply when you access or use the Services. All references to “you” or “your” refer to you and the entity on behalf of which you use the Services.
BY INSTALLING, DOWNLOADING, REGISTERING WITH, ACCESSING, OR USING THE SERVICES OR SUBMITTING CONTENT (AS DEFINED BELOW) TO THE SERVICES, YOU AGREE TO THE TERMS AND CONDITIONS CONTAINED IN THIS AGREEMENT. IF YOU DO NOT ACCEPT THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT USE, ACCESS, OR SUBMIT CONTENT TO THE SERVICES.
We may modify this Agreement at any time. All changes to the Agreement will be posted on this page and are effective as of the date they are posted. Accordingly, you should periodically visit this page to review the terms of this Agreement. Your use of the Services after a modification to this Agreement constitutes your acceptance of the modification.
You should also review our <a href="/privacy-and-policy">Privacy Policy</a>
, the terms of which are incorporated herein by reference and made a part of this Agreement.
          </p>
          <h2>1. Healthcare Disclaimer</h2>
          <p>
          The medical information on this site is provided as an information resource only and is not to be used or relied on for any diagnostic or treatment purposes. This information is not intended to be patient education, does not create any patient-physician relationship, and should not be used as a substitute for professional diagnosis and treatment.
Please consult your health care provider before making any healthcare decisions or for guidance about a specific medical condition. Health PlusCost expressly disclaims responsibility, and shall have no liability, for any damages, loss, injury, or liability whatsoever suffered as a result of your reliance on the information contained in this site. Health PlusCost does not endorse specifically any test, treatment, physicians, products, procedures, opinions, or other information that may be mentioned on the site.  Never disregard professional medical advice or delay in seeking it because of something you have read on the Services.
If you think you may have a medical emergency, call your doctor or 911 immediately. Reliance on any information provided by Health PlusCost, Health PlusCost employees, others appearing on the Services at the invitation of Health PlusCost, or other visitors to the Website or App is solely at your own risk.
          </p>
          <h2>2. Ownership</h2>
          <p>
          Health PlusCost owns all information, text, reports, data, databases, graphics, images, sound recordings, audio and visual clips, photographs, programs, applications, software, and other content (except User Content, defined below) contained in the Services, as well as the collection, design, selection and arrangement thereof (collectively, the “Health PlusCost Material”). The names, marks, and logos appearing on the Services (collectively, the “Trademarks”) are owned by or licensed to Health PlusCost and are used with permission of the owner. The Trademarks and Health PlusCost Material are protected by trademark, copyright, and other intellectual property laws. Health PlusCost requires you to respect the intellectual property rights that we have in the Health PlusCost Material and in the Trademarks.
          </p>
          <h2>3. License</h2>
          <p>Health PlusCost hereby grants you a limited, non-exclusive, revocable, non-transferable license to view and use the Services in accordance with the terms of this Agreement. You may view, print, or save copies of the Health PlusCost Material as required to maintain the products or services that you purchase from Health PlusCost and for backup or disaster recovery purposes. If you subscribe to any fee-based services provided by Health PlusCost via the Services, this license is contingent upon your payment of any applicable subscription fees and your compliance with any other terms and conditions applicable to you as a subscriber. You agree to retain all proprietary notices on any copy you make of the Health PlusCost Material. This license does not give you any ownership or intellectual property interest in any Health PlusCost Material or the Trademarks.
Other than as required to facilitate your permissible use, you may not reproduce, perform, publicly display, create derivative works of, republish, upload, post, retransmit or redistribute in any way whatsoever any Health PlusCost Material. Any reproduction, publication, distribution, assignment, sublicense, sale, or other uses of the Health PlusCost Material for commercial purposes without written permission from Health PlusCost is strictly prohibited. All rights not expressly granted to you under this Agreement are reserved by Health PlusCost.
</p>
<h2>4. Personal Information</h2>
<p>
You may be required to submit information such as your name, physical address, e-mail address, home and mobile telephone numbers, or birth date (“Personal Information”) to use the Services. Your Personal Information will be handled in accordance with our <a href="/privacy-and-policy">Privacy Policy</a>
</p>
<h2>5. Account Information</h2>
<p>
You understand that you will need to create an account in order to use the Services and benefit from its content and functions. You will (a) provide true, accurate, current and complete information about yourself as prompted by the Services’ registration, sign-in, or subscription page (such information being the “Registration Data”); and (b) maintain and properly update the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete or we have reasonable grounds to suspect that such information is untrue, inaccurate, not current or complete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services.
You must provide certain Personal Information to register for an account and you must create a unique username and password that enables you to access your account. You agree not to disclose your account details, including your username and password, to anyone else or permit any third party to logon to the Services using your account information. You are solely responsible for any activities or actions taken under your account, whether or not authorized by you. You agree to immediately notify Health PlusCost immediately of any unauthorized use of your account or nay other breach of security of which you become aware. Health PlusCost is not liable for any loss or damage from your failure to comply with these requirements. By creating an account you consent to the use of electronic means to review and accept this Agreement and to provide you with any notices given pursuant to this Agreement.
</p>
<h2>6. User Content</h2>
<p>
You may submit photographs, images, videos, information, comments, suggestions, graphics, drawings, designs, or other similar materials (collectively, “User Content”) to Health PlusCost via the Services. By uploading or submitting any User Content to Health PlusCost, you represent and warrant that you are the original author, owner, or licensee of such User Content and all rights thereto, and that you have the right to upload or submit the User Content. When you submit User Content to Health PlusCost, you grant Health PlusCost a non-exclusive, worldwide, perpetual, irrevocable, sub-licensable, royalty-free, freely transferable right and license to use, copy, modify, create derivative works of, delete, transmit, publish, display, perform, translate, sell, and distribute the User Content, and to incorporate such User Content into any form or medium now known or hereinafter invented, without compensation to you. You also warrant that all moral rights or publicity rights that may have been in such User Content are voluntarily waived by you. Health PlusCost reserves the right to refuse any User Content. No User Content will be subject to any obligation of confidence on the part of Health PlusCost.
You agree not to do any of the following: (i) transmit to the Services material that is copyrighted, unless you are the copyright owner or have the permission of the copyright owner to send it; (ii) send material that reveals trade secrets, unless you own them or have the permission of the owner to reveal them; (iii) send material that infringes on any intellectual property rights of others or on the privacy or publicity rights of others; (iv) send material that is obscene, defamatory, threatening, harassing, abusive, hateful, or embarrassing to another person or entity; (v) send sexually-explicit images; (vi) send advertisements or solicitations of business; (vii) send chain letters or pyramid schemes; (viii) impersonate another person or entity; (ix) post, upload or share any content or information that is false, inaccurate or misleading; or (x) post, upload or share any content or information that contains any virus, worm, malware or other potentially damaging programs or data.
You agree that disputes arising from an alleged violation of intellectual property rights may result in Health PlusCost or its affiliates suffering irreparable harm and that, in the event of such a dispute, Health PlusCost or its affiliates may seek a restraining order, preliminary injunctive relief, an injunction, specific performance or other equitable relief and/or legal remedies.
</p>
<h2>7. Availability</h2>
<p>
We will use reasonable efforts to make the Services available at all times. However, you acknowledge the Services are provided over the Internet and mobile networks and so the quality and availability of the Services may be affected by factors outside our reasonable control. We, including without limitation our affiliates and subcontractors, do not accept any responsibility whatsoever for unavailability of the Services, or any difficulty or inability to download or access content or any other communication system failure which may result in the Services being unavailable.
</p>
<h2>8. Prohibited Acts</h2>
<p>
The following is a non-exhaustive list of activities that are prohibited in connection with the Services:</p>
<ul>
  <li>Criminal or tortious activity, including child pornography, fraud, trafficking in obscene material, gambling, harassment, stalking, spamming, sending of viruses or other harmful files, trademark infringement, copyright infringement, patent infringement, or trade secret theft;</li>
  <li>Any automated use of the Services or User Content, such as using scripts to add subscribers or send comments or messages;</li>
  <li>Interfering with, disrupting, or creating an undue burden on the Services, or otherwise using the Services in any manner that could disable, overburden, damage, or impair the Services or interfere with any other party’s use of the Services, including their ability to engage in real-time activities through the Services;</li>
  <li>Introducing any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful, or to attack the Services via a denial-of-service attack or distributed denial-of-service attack, or otherwise attempt to interfere with the proper working of the Services;</li>
  <li>Using any robot, spider, or other automatic device, process, or means to access the Services for any purpose, including monitoring or copying any of the material on the Services;</li>
  <li>Attempting to impersonate another person or entity;</li>
  <li>Using any information obtained from the Services to harass, abuse, or harm another person;</li>
  <li>Accepting payment or anything of value from a third person in exchange for your performing any commercial activity on the Services on behalf of that person; or</li>
  <li>Using the Services in a manner inconsistent with applicable laws and regulations.</li>
</ul>
<p>Health PlusCost reserves the right to investigate and take appropriate legal action against anyone who, in Health PlusCost’s sole discretion, violates this section, including without limitation, reporting such violations to law enforcement authorities.</p>

<h2>9. Termination</h2>
<p>
Health PlusCost may, in its sole discretion, terminate this Agreement by discontinuing operation of the Services. Health PlusCost reserves the right to terminate your account and prevent you from accessing the Services at any time and for any reason, including but not limited to your violation of this Agreement or the law. The violation of any of the terms and conditions set forth in this Agreement may result in the immediate termination of your right and license to use the Health PlusCost Material and obligates you to immediately destroy any copies of the Health PlusCost Material in your possession.
</p>
<h2>10. Disclaimer of Warranties</h2>
<p>
THE SERVICES AND THE Health PlusCost MATERIAL ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND. Health PlusCost, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES’ RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE.
Although Health PlusCost strives for accuracy in all elements of the Services and the Health PlusCost Material, the Services or Health PlusCost Material may contain inaccurate or out-of-date information or typographical or spelling errors. Health PlusCost does not make any representations or warranties regarding: (i) the truth, accuracy, completeness, timeliness, legality, or reliability of any Health PlusCost Material; (ii) any opinion, information, advice or statement expressed by Health PlusCost on the Services; or (iii) any results to be obtained from using the Services or relying on the Health PlusCost Material. Health PlusCost does not warrant that: (i) the quality of any products, services, information, or other material that you obtain from the Services will meet your expectations; (ii) the Services will operate uninterrupted and error-free; (iii) the Services will be free of viruses, worms or other harmful material; or (iv) the Services will operate in perpetuity.
</p>
<h2>11. Limitation of Liability</h2>
<p>
YOU USE THE SERVICES AND THE HEALTH PLUSCOST MATERIAL AT YOUR OWN RISK. TO THE EXTENT PERMITTED BY APPLICABLE LAW, HEALTH PLUSCOST SHALL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES IN ANY WAY ARISING FROM OR RELATING TO YOUR RELIANCE UPON, OR YOUR USE OF OR INABILITY TO USE THE SERVICES OR THE HEALTH PLUSCOST MATERIAL OR ANY USER CONTENT, WHETHER SUCH CLAIMS ARE BASED IN CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT HEALTH PLUSCOST IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
TO THE EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL HEALTH PLUSCOST OR ITS PARENT, SUBSIDIARIES, OFFICERS, DIRECTORS, AFFILIATES, EMPLOYEES, OR AGENTS BE RESPONSIBLE FOR ANY LOSS OR DAMAGE, INCLUDING PERSONAL INJURY, DEATH, OR PROPERTY DAMAGE, RESULTING FROM YOUR USE OF THE SERVICES OR HEALTH PLUSCOST MATERIAL OR FROM ANY USER CONTENT SUBMITTED TO THE SERVICES. HEALTH PLUSCOST SHALL NOT BE RESPONSIBLE FOR ANY COSTS OR EXPENSES, INCLUDING, WITHOUT LIMITATION, THE SERVICING OR REPLACING OF EQUIPMENT, RESULTING FROM YOUR USE OF THE SERVICES.
HEALTH PLUSCOST ASSUMES NO RESPONSIBILITY OR LIABILITY FOR ANY LOST DATA, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMMUNICATIONS LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, OR ALTERATION OF, ANY USER COMMUNICATION. HEALTH PLUSCOST IS NOT RESPONSIBLE OR LIABLE FOR ANY PROBLEMS OR TECHNICAL MALFUNCTION OF ANY TELEPHONE NETWORK OR LINES, COMPUTER ONLINE SYSTEMS, SERVERS OR PROVIDERS, COMPUTER EQUIPMENT, MOBILE DEVICES, SOFTWARE, OR FAILURE OF EMAIL DUE TO TECHNICAL PROBLEMS OR TRAFFIC CONGESTION ON THE INTERNET, INCLUDING INJURY OR DAMAGE TO ANY COMPUTER OR MOBILE DEVICE RELATED TO OR RESULTING FROM USE OF THE SERVICES.
</p>
<h2>12. Third Party Websites</h2>
<p>
The Services may contain links to third party websites. Health PlusCost is not responsible for the content, accuracy or opinions expressed on such websites, and such websites are in no way investigated, monitored or checked for accuracy or completeness by Health PlusCost. Inclusion of any linked third party website on the Services does not imply approval or endorsement by Health PlusCost. When you access these third party websites, you do so at your own risk and become subject to the terms of use and privacy policies of those third party websites.
</p>
<h2>13. Compliance with Laws</h2>
<p>
You agree to comply with all applicable local, state, national and international laws, rules and regulations, including without limitation, laws governing online conduct and the unlawful export of software or technical data to restricted individuals or countries.
</p>
<h2>14. Additional Terms</h2>
<p>
Occasionally, Health PlusCost may notify you of additional terms and conditions that you are required to accept in order to receive certain services offered on or through the Services (the “Additional Terms”), which will be posted in specific areas and require reading and acceptance. The Additional Terms are incorporated herein by reference and made a part of this Agreement.
</p>
<h2>15. Indemnification</h2>
<p>
You agree to defend, indemnify, and hold harmless Health PlusCost and its parent, subsidiaries, affiliates, officers, directors, employees, and agents from and against any action, claim, demand, damages, costs or expenses (including attorneys’ fees and court costs) arising out of: (i) your use of the Services or the Health PlusCost Material; (ii) any conduct by you that violates the terms of this Agreement; or (iii) your infringement of the intellectual property rights of third parties.
</p>
<h2>16. General Provisions</h2>
<p>
This Agreement is governed by the laws of State of New York, without regard to its conflict of laws principles. You agree to submit to the jurisdiction of the courts in New York County, New York, U.S.A., with respect to any dispute, disagreement, or cause of action related to the Services.
Health PlusCost’s failure to enforce any provision hereof shall not constitute or be construed as a waiver of such provision or of the right to enforce it or any other provision at a later time.
Health PlusCost may, at any time, in its sole discretion, and without notice to you, assign some or all of its rights and obligations under this Agreement. You may not assign your rights or delegate your duties under this Agreement without the prior written consent of Health PlusCost.
If any provision of this Agreement is held invalid, illegal or unenforceable for any reason, such invalidity, illegality or unenforceability shall be deleted and shall not affect any other provision of this Agreement.
Except as expressly provided elsewhere on the Services, this Agreement constitutes the entire agreement between you and Health PlusCost with respect to your use of the Services.
</p>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
