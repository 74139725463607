import React from "react";
import "./ArticleCard.css";

const ArticleCard = () => {
  return (
    <div className="articleCard">
      <img src="./images/article.png" className="articleCardImg" alt=""></img>
      <h2>Stress, Burnout, and Moral...</h2>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since
      </p>
    </div>
  );
};

export default ArticleCard;
