import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import PageTItle from "../../components/PageTitle/PageTItle";
import "./FAQs.css";
const accor = [
  {
   
    data: [
      {
        id: 1,
        question: "What procedures do you cover?",
        answer: "Health PlusCost currently provides the pricing of 20 different procedures. We have purposely curated these procedures to be the 20 most popular procedures in New York City to provide NYC residents with the information they need. We are currently working on providing pricing information for more procedures, though!",
      },
      {
        id: 2,
        question: "What insurances do you cover?",
        answer:
          "Health PlusCost currently provides the pricing of 8 of the most popular insurance plans in NYC. This includes: Fidelis Care, Empire Bluecross, Unitedhealthcare, univera Healthcare, Independent Health, Healthfirst (NY), CDPHP, and Oscar. We are currently working on providing pricing information for more insurances, though!",
      },
      {
        id: 3,
        question: "What zipcodes do you cover?",
        answer:
          "We provide pricing information for all zipcodes in the New York City area! We are currently looking to expand to more popular cities in the United States, though, so shoot us an email if you would like to reccomend a city!",
      },
    ],
  },
  {

    data: [
      {
        id: 4,
        question: "Where is this data from?",
        answer: "Thanks to the Hospital Price Transparency Final Rule, it is now required by hospitals to disclose the standard charges for each item or service it provides, therefore, all hospital items and services for which the hospital has established a standard charge must be listed. Of course, as stated in our disclaimer, these pricings are always subject to change and our goal is to serve as a baseline for what you may be expected to pay. It is always suggested by Health PlusCost that you reach out to the hospital and directly inquire about their pricing according to the procedure you are interested in.",
      },
      {
        id: 5,
        question: "Why specifically focus on NYC?",
        answer:
          "Our two founders are college students that reside in NYC, and with a population of over 8 million people, this condensed area is where we wanted to begin our focus. Further, it is estimated that 4-14% of New York residents are in medical debt. With the median income just shy of $35,000, and recently named the most expensive city in the United States, we hope to alleviate some costs and provide insight on how you can cut medical costs.",
      },
      {
        id: 6,
        question: "Any plans on expanding?",
        answer:
          "Absolutely! Aside from just expanding locationally, we are in the process of adding more procedures to our NYC database. Further, we are reaching out to private providers and include their pricing as well. Have suggestions on what procedures you want to see? Please email us - we would love to hear from you!",
      },
    ],
  },
  {
    
    
    
    data: [
      {
        id: 7,
        question: "What city is next?",
        answer: "We are in the process of expanding to San Francisco due to popular demand. Of course, please send us an email any time if you have any suggestions - we are here to serve you!",
      },
      {
        id: 8,
        question: "Why do you ask for location?",
        answer:
          "Health PlusCost simply asks for your location so that we can utilize the Google Maps API to allow you to see how far away each hospital is. Of course, you do not have to share your location to view pricing - just distance. We absolutely do not store our users locations.",
      },
      {
        id: 9,
        question: "Who are the founders?",
        answer:
          "Our two founders are NYC college students, studying computer science and finance, respectively. As first generation Americans and college students who have had to deal with medical debt, it was imperative to us to use our skills to provide some transparency in a space that provides none.",
      },
    ],
  },
];

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      <PageTItle
        title={"Frequently Asked Questions"}
        desc={"Search our FAQ for answers to anything you might ask."}
      />
      <div className="faq-container">
        <p className="faqs-history">Help Center {">"} faq</p>
        {accor.map((data) => (
          <div className="Faqs">
            <h1>{data.title}</h1>

            <div className="accordion">
              {data.data.map((item) => (
                <div className="accordion-item" key={item.id}>
                  <div
                    className="accordion-header"
                    onClick={() => handleClick(item.id)}
                  >
                    <p className="faq-question">{item.question}</p>
                    <span>
                      {activeIndex === item.id ? (
                        <FontAwesomeIcon
                          icon={faMinus}
                          style={{ fontWeight: 300, color: "#122853" }}
                          size="0.5x"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{ fontWeight: 300, color: "#122853" }}
                          size="0.5x"
                        />
                      )}
                    </span>
                  </div>
                  {activeIndex === item.id && (
                    <div className="faq-answer">
                      <p>{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQs;
