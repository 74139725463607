import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import PageTItle from "../../components/PageTitle/PageTItle";
import "./Chat.css";
import axios from "axios";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const Chat = () => {
  const [message, setMessage] = useState("");
  const [conversation, setConversation] = useState([]);
  const [chatSuggestions, setChatSuggestions] = useState([
    "What are some common COVID-19 symptoms?", 
    "How do I know if I need to see a doctor?", 
    "What types of health concerns can be addressed with Dr. LISA?"
  ]);

  useEffect(() => {
    const initialMessage = { text: "Hi, I'm Dr. LISA! How can I help you today?", sender: "Dr. LISA" };
    setConversation([initialMessage]);
  }, []);

  useEffect(() => {
    updateChatSuggestions();
  }, [conversation]);

  const sendMessage = async (event) => {
    event.preventDefault();
    const userMessage = { text: message, sender: "You" };
    setConversation((oldConversation) => [...oldConversation, userMessage]);
    const botTypingMessage = { text: "Thinking...", sender: "Dr. LISA", typing: true };
    setConversation((oldConversation) => [...oldConversation, botTypingMessage]);
    setTimeout(async () => {
       const response = await axios.post("https://health-plus-cost.herokuapp.com/api/v1/chat", {
        message,
      });
      const data = response.data;
      const botResponse = { text: data.choices[0].text.trim().replace(/^Dr\. LISA:\s*/, ""), sender: "Dr. LISA" };
      setConversation((oldConversation) => [...oldConversation.slice(0, -1), botResponse]);
    }, 1500);
    setMessage("");
  };

  const handleSuggestionClick = (suggestion) => {
    setMessage(suggestion);
  };

    // Shuffle an array
    const shuffleArray = (array) => {
      let currentIndex = array.length, randomIndex;
    
      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
    
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
      }
    
      return array;
    }
  

  const generateSuggestions = (conversation) => {
    const keywordsAndResponses = {
      "covid-19": ["What are some common COVID-19 symptoms?", "What's the latest on the COVID-19 pandemic?"],
      "doctor": ["How do I know if I need to see a doctor?", "What type of doctor should I see for my condition?"],
      "health": ["What types of health concerns can be addressed with Dr. LISA?", "What's a healthy diet like?"],
      // Add more keywords and responses as per your needs
      "exercise": ["What's the ideal workout routine for beginners?", "What are some home exercises to stay fit?"],
      "nutrition": ["What is a balanced diet?", "What foods are high in antioxidants?"],
      "mental health": ["How can I manage stress effectively?", "What are some common symptoms of anxiety or depression?"],
      "sleep": ["What are the best practices for good sleep hygiene?", "How does lack of sleep affect my health?"],
      "hydration": ["How much water should I drink in a day?", "What are signs of dehydration?"],
      "allergies": ["What are common symptoms of food allergies?", "How can I differentiate between a cold and an allergy?"],
      "weight": ["What are some healthy ways to lose weight?", "How can I maintain a healthy weight?", "Is BMI reliable?"],
      "vaccine": ["What vaccinations are recommended for adults?", "Why are childhood immunizations important?"],     
      "diabetes": ["What are some lifestyle changes for managing diabetes?", "What are the symptoms of Type 1 and Type 2 diabetes?"],
      "cardiovascular health": ["What exercises are good for heart health?", "What foods are good for maintaining a healthy heart?"],
      "smoking cessation": ["What are some effective strategies to quit smoking?", "What are the health benefits of quitting smoking?"],
      "skin": ["How can I protect my skin from sun damage?", "What is a good skincare routine?"],
      "teeth": ["What is the correct way to brush teeth?", "Why is flossing important for dental health?"],
       
    };

    let matchedResponses = [];

    conversation.forEach((entry) => {
      const message = entry.text.toLowerCase();

      for (let keyword in keywordsAndResponses) {
        if (message.includes(keyword)) {
          keywordsAndResponses[keyword].forEach(response => {
            if (!matchedResponses.includes(response)) {
              matchedResponses.push(response);
            }
          });
        }
      }
    });

    if (matchedResponses.length === 0) {
      matchedResponses = [
        "Do I need to provide any personal information?",
        "Is there a cost for using Dr. LISA?",
        "What types of health concerns can be addressed with Dr. LISA?",
      ];
    }

        // Shuffle the array and then slice it to the first 4 items.
        return shuffleArray(matchedResponses).slice(0, 4);


    // return matchedResponses;
  }

  const updateChatSuggestions = () => {
    setChatSuggestions(generateSuggestions(conversation));
  };
  return (
    <>
      <PageTItle title={"Chat with Dr. LISA"} />
      <div className="TermsConditions">
        <p>
          Home {">"} Chat with Us{" "}
        </p>
        <div className="termSec">
          <h1>Your Personal Doctor</h1>
          <p>
            We're thrilled to announce the launch of our newest feature - Dr.
            LISA (Life-saving Intelligent Support Assistant), your personal
            medical guide that's on call 24/7. Harnessing the power of advanced
            artificial intelligence technology, Dr. LISA is poised to offer you
            immediate, comprehensive, and personalized health information
            anytime, anywhere. This seamless integration of technology and
            healthcare is another step in our commitment to providing you with
            the best care possible. No matter what your health query, Dr. LISA
            is ready to assist.
          </p>
        </div>

        <div className="chatbox">
          <div className="chat-log">
          {conversation.map((entry, index) => (
  <div
    key={index}
    className={`chat-entry ${entry.sender === "You" ? "user" : "bot"}`}
  >
    {entry.sender === "You" ? (
      <>
        <span className="message-bubble">{entry.text}</span>
        <div className="user-icon">
          <span className="user-icon-circle">
            <FontAwesomeIcon icon={faUser} />
          </span>
        </div>
      </>
    ) : (
      <>
        <div className="bot-icon">
          <span className="bot-icon-circle">Dr.</span>
        </div>
        <span className={`message-bubble ${entry.typing ? "typing" : ""}`}>
          {entry.text}
        </span>
      </>
    )}
  </div>
))}

          </div>

          

          <div className="chat-suggestions">
            {chatSuggestions.map((suggestion, index) => (
              <button
                key={index}
                className="chat-suggestion"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </button>
            ))}
          </div>
          

          <form onSubmit={sendMessage} className="chat-form">
            <input
              type="text"
              value={message}
              placeholder="Start chatting away!"
              onChange={(event) => setMessage(event.target.value)}
              className="chat-input"
            />
            <button type="submit" className="chat-button">
              Send
            </button>
          </form>
        </div>

        <div className="termSubSec">
          <h1>Dr. LISA (Life-saving Intelligent Support Assistant)</h1>
          <div className="contents">
            <div className="content">
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "24px", color: "blue", marginRight: "10px" }}
              />
              <p>
                Our innovative chatbot, powered by OpenAI's GPT-4, serves as a virtual medical companion ready to discuss a wide range of health topics. Whether you have questions about common illnesses, symptoms, treatment options, or health maintenance, our chatbot is equipped to provide detailed and reliable information. It's like having a doctor on call around the clock, ready to offer guidance and answer your queries whenever you need.
              </p>
            </div>
            <div className="content">
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "24px", color: "blue", marginRight: "10px" }}
              />
              <p>
                This service is especially useful for those middle-of-the-night health concerns, when you need immediate information but it's not urgent enough for a hospital visit. It's also perfect for those looking to educate themselves more about health topics relevant to their personal well-being.
              </p>
            </div>
            <div className="content">
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "24px", color: "blue", marginRight: "10px" }}
              />
              <p>
                Our chatbot service is an ideal tool for those trying to navigate the often complex world of medical terminology. Whether you're attempting to understand a diagnosis, interpret medical jargon from a recent doctor's visit, or simply curious about the implications of certain medical terms, our chatbot can provide clear, understandable explanations, making healthcare information more accessible and less intimidating.
              </p>
            </div>
            <div className="content">
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "24px", color: "blue", marginRight: "10px" }}
              />
              <p>
                For individuals managing chronic conditions, our chatbot can serve as a continuous source of information and guidance. By offering insights on symptom management, lifestyle modifications, and treatment options, this tool aids in empowering users with knowledge, facilitating informed discussions with healthcare providers and promoting active participation in managing their health.
              </p>
            </div>
            <div className="content">
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "24px", color: "blue", marginRight: "10px" }}
              />
              <p>
                <strong>Disclaimer: </strong>Please note that while our chatbot strives to provide accurate and current information, it does not replace professional medical advice, diagnosis, or treatment. It's important to consult with a healthcare professional for all health-related concerns. Always seek the advice of your doctor or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on this website. The use of any information provided by our chatbot is solely at your own risk.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
