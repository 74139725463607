import React from "react";
import PageTItle from "../../components/PageTitle/PageTItle";
import "./About.css";

const About = () => {
  return (
    <div>
      <PageTItle
        title={"About Health PlusCost"}
      />
      <div className="privacy">
        <p>Support {">"} About</p>
        <div className="section1 section">
          <h1>Health PlusCost</h1>
          <p>
          At Health PlusCost, we are committed to making healthcare pricing transparent and accessible. Our mission is to empower patients by providing upfront cost information for a wide range of medical procedures. We have partnered with top healthcare providers in the NYC area to ensure that patients have access to accurate and reliable pricing information. Whether you need an MRI scan, mammogram, colonoscopy, or any other common medical procedure, we've got you covered.
          <br></br>
          <br></br>
          Our user-friendly platform allows patients to easily compare prices from the top 8 insurance providers in the area. We believe that informed decisions about healthcare should not be limited to medical professionals. By providing patients with upfront pricing information, we aim to reduce financial stress and improve access to quality healthcare. We are dedicated to promoting price transparency and helping patients find the best insurance plan for their needs.
      <br></br>
  
          </p>
        </div>
        <div className="section2 section">
          <h1>Other Health PlusCost Products</h1>
          <h2>a. Dr.LISA (Life-saving Intelligent Support Assistant) </h2>
          <p>
          Dr. LISA (Life-saving Intelligent Support Assistant) is an AI-powered chatbot that serves as a virtual medical companion. With Dr. LISA, patients can access detailed and reliable information about common illnesses, symptoms, treatment options, and more. Our chatbot is available 24/7, providing immediate support and guidance.
          <br></br>
          <br></br>
          Test out <a href="/Chat">Dr. LISA</a> now and let us know what you think!
          </p>

          <h2>b. CASH (Cost-savings Assistance for Health)</h2>
          <p>CASH (Cost-savings Assistance for Health) is another groundbreaking service we offer. We understand the financial burden that medical expenses can impose on individuals and families. That's why we have developed CASH to help patients navigate and negotiate their medical bills. Our experienced negotiators review your medical bills, advocate on your behalf, and strive to lower the costs. With CASH, you can save a significant amount of money on your medical expenses, ensuring that you receive quality care at an affordable price.
<br></br>
<br></br>
Interested? Fill out our form at <a href="/Billing">CASH</a> now and let us know what you think!
</p>
</div>
</div>
    </div>
  );
};

export default About;
