import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Signup from "./pages/Auth/Signup";
import Signin from "./pages/Auth/Signin";
import User from "./pages/User/User";
import Articles from "./pages/Articles/Articles";
import Hospitals from "./pages/Hospitals/Hospitals";
import FAQs from "./pages/FAQs/FAQs";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import MedicalCenterDetails from "./pages/MedicalCenterDetails/MedicalCenterDetails";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import HospitalDetails from "./pages/HospitalDetails/HospitalDetails";
import Disclaimer from "./pages/Disclaimer/disclaimer";
import Chat from "./pages/Chat/Chat";
import Billing from "./pages/Billing/Billing";
import About from "./pages/About/About";
import { useSelector } from "react-redux";

import ReactGA from "react-ga";
import PopularHospitals from "./pages/PopularHospitals/PopularHospitals";

// Initialize Google Analytics with your tracking ID
ReactGA.initialize(process.env.GA_TRACKING_ID);

function App() {
  const user = useSelector((state) => state.user.currentUser);
  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sign-up" element={user ? <Home /> : <Signup />} />
          <Route path="/sign-in" element={user ? <Home /> : <Signin />} />
          <Route path="/me" element={user ? <User /> : <Signin />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/popular-hospitals" element={<PopularHospitals />} />
          <Route path="/Chat" element={<Chat />} />
          <Route path="/popular-hospitals/:id" element={<HospitalDetails />} />
          <Route path="/hospitals" element={<Hospitals />} />
          <Route path="/hospitals/:procedure" element={<Hospitals />} />
          <Route path="/frequently-asked-questions" element={<FAQs />} />
          <Route path="/hospitals" element={<Hospitals />} />
          <Route path="/Billing" element={<Billing />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/privacy-and-policy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/About" element={<About />} />
          <Route
            path="/hospitals-list/:hospital"
            element={<MedicalCenterDetails />}
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
