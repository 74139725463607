import React from "react";
import ArticleCard from "../../components/ArticleCard/ArticleCard";
import "./articles.css";
import PageTitle from "../../components/PageTitle/PageTItle";

const Articles = () => {
  return (
    <>
      <PageTitle title="Articles" desc="Articles Text" />
      <div className="articles">
        <ArticleCard />
        <ArticleCard />
        <ArticleCard />
        <ArticleCard />
        <ArticleCard />
      </div>
    </>
  );
};

export default Articles;
