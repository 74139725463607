import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/userRedux";

const Navbar = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);

  const handleClick = () => {
    dispatch(logOut());
  };

  return (
    <div className="navbar">
      <nav className="navigation">
        <input
          style={{ display: "none" }}
          type="checkbox"
          className="menu-btn"
          id="menu-btn"
        />

        <label
          onClick={() => setShow(!show)}
          for="menu-btn"
          className="menu-icon"
        >
          <span className="nav-icon"></span>
        </label>
        <Link to={"/"} className="logo">
          <span>Health</span>PlusCost
        </Link>

        <ul className="menu" id={show ? "show" : ""}>
  <li>
    <Link to={"/popular-hospitals"}>Popular Hospitals</Link>
  </li>
  <li className="lisa">
    <Link to={"/Chat"}>Dr. LISA</Link>
  </li>
  <li className="cash">
    <Link to={"/Billing"}>CASH</Link>
  </li>
  <li id="loginBtn">
    <Link to={"/sign-in"}>Sign In</Link>
  </li>
  <li>
    <Link to={"/hospitals"}>Search</Link>
  </li>
</ul>


        {user ? (
          <div class="avatar">
            <img src={`${user ? user.user.image : <>J</>}`} alt="User Avatar" />
            <div class="dropdown">
              <div class="dropdown-content">
                <Link to={"/me"}>Profile</Link>
                <Link to="/" onClick={handleClick}>
                  Logout
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <Link to={"/sign-in"} className="nav-appointment-btn">
            Sign In
          </Link>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
