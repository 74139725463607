export const ZipCodes = [
  { zipCode: "10312" },
  { zipCode: "10314" },
  { zipCode: "10301" },
  { zipCode: "10305" },
  { zipCode: "10306" },
  { zipCode: "10309" },
  { zipCode: "10304" },
  { zipCode: "10308" },
  { zipCode: "10307" },
  { zipCode: "10303" },
  { zipCode: "11415" },
  { zipCode: "10302" },
  { zipCode: "11377" },
  { zipCode: "11355" },
  { zipCode: "11375" },
  { zipCode: "11432" },
  { zipCode: "11420" },
  { zipCode: "11419" },
  { zipCode: "11413" },
  { zipCode: "11374" },
  { zipCode: "11365" },
  { zipCode: "11367" },
  { zipCode: "11421" },
  { zipCode: "11357" },
  { zipCode: "11101" },
  { zipCode: "11418" },
  { zipCode: "11412" },
  { zipCode: "11106" },
  { zipCode: "11691" },
  { zipCode: "11372" },
  { zipCode: "11378" },
  { zipCode: "11358" },
  { zipCode: "11379" },
  { zipCode: "11105" },
  { zipCode: "11433" },
  { zipCode: "11364" },
  { zipCode: "11103" },
  { zipCode: "11369" },
  { zipCode: "11102" },
  { zipCode: "11422" },
  { zipCode: "11417" },
  { zipCode: "11423" },
  { zipCode: "11361" },
  { zipCode: "11416" },
  { zipCode: "11414" },
  { zipCode: "11429" },
  { zipCode: "11104" },
  { zipCode: "11370" },
  { zipCode: "11356" },
  { zipCode: "11427" },
  { zipCode: "11692" },
  { zipCode: "11426" },
  { zipCode: "11694" },
  { zipCode: "11411" },
  { zipCode: "11428" },
  { zipCode: "11436" },
  { zipCode: "11360" },
  { zipCode: "11362" },
  { zipCode: "11415" },
  { zipCode: "11366" },
  { zipCode: "11004" },
  { zipCode: "11693" },
  { zipCode: "11096" },
  { zipCode: "11363" },
  { zipCode: "11697" },
  { zipCode: "11439" },
  { zipCode: "11005" },
  { zipCode: "11109" },
  { zipCode: "11424" },
  { zipCode: "11430" },
  { zipCode: "11425" },
  { zipCode: "11359" },
  { zipCode: "11690" },
  { zipCode: "11431" },
  { zipCode: "11695" },
  { zipCode: "11120" },
  { zipCode: "11351" },
  { zipCode: "11352" },
  { zipCode: "11371" },
  { zipCode: "11380" },
  { zipCode: "11381" },
  { zipCode: "11386" },
  { zipCode: "11405" },
  { zipCode: "11451" },
  { zipCode: "11499" },
  { zipCode: "10020" },
  { zipCode: "10036" },
  { zipCode: "10029" },
  { zipCode: "10037" },
  { zipCode: "10039" },
  { zipCode: "10001" },
  { zipCode: "10011" },
  { zipCode: "10018" },
  { zipCode: "10019" },
  { zipCode: "10026" },
  { zipCode: "10027" },
  { zipCode: "10030" },
  { zipCode: "10035" },
  { zipCode: "10010" },
  { zipCode: "10016" },
  { zipCode: "10017" },
  { zipCode: "10022" },
  { zipCode: "10012" },
  { zipCode: "10013" },
  { zipCode: "10014" },
  { zipCode: "10004" },
  { zipCode: "10005" },
  { zipCode: "10006" },
  { zipCode: "10007" },
  { zipCode: "10038" },
  { zipCode: "10028" },
  { zipCode: "10002" },
  { zipCode: "10003" },
  { zipCode: "10009" },
  { zipCode: "10021" },
  { zipCode: "10044" },
  { zipCode: "10065" },
  { zipCode: "10075" },
  { zipCode: "10128" },
  { zipCode: "10023" },
  { zipCode: "10024" },
  { zipCode: "10025" },
  { zipCode: "10031" },
  { zipCode: "10040" },
  { zipCode: "10032" },
  { zipCode: "10033" },
  { zipCode: "10034" },
  { zipCode: "10467" },
  { zipCode: "10456" },
  { zipCode: "10458" },
  { zipCode: "10453" },
  { zipCode: "10468" },
  { zipCode: "10457" },
  { zipCode: "10452" },
  { zipCode: "10462" },
  { zipCode: "10469" },
  { zipCode: "10466" },
  { zipCode: "10473" },
  { zipCode: "10460" },
  { zipCode: "10451" },
  { zipCode: "10459" },
  { zipCode: "10461" },
  { zipCode: "10465" },
  { zipCode: "10455" },
  { zipCode: "10475" },
  { zipCode: "10705" },
  { zipCode: "10454" },
  { zipCode: "10471" },
  { zipCode: "10474" },
  { zipCode: "10464" },
  { zipCode: "11233" },
  { zipCode: "11238" },
  { zipCode: "11209" },
  { zipCode: "11214" },
  { zipCode: "11228" },
  { zipCode: "11204" },
  { zipCode: "11234" },
  { zipCode: "11236" },
  { zipCode: "11239" },
  { zipCode: "11223" },
  { zipCode: "11224" },
  { zipCode: "11229" },
  { zipCode: "11235" },
  { zipCode: "11201" },
  { zipCode: "11205" },
  { zipCode: "11215" },
  { zipCode: "11217" },
  { zipCode: "11231" },
  { zipCode: "11203" },
  { zipCode: "11210" },
  { zipCode: "11225" },
  { zipCode: "11226" },
  { zipCode: "11207" },
  { zipCode: "11208" },
  { zipCode: "11237" },
  { zipCode: "11232" },
  { zipCode: "11206" },
  { zipCode: "11221" },
];

export const Insurances = [
  { insurance: "Fidelis care" },
  { insurance: "Empire bluecross" },
  { insurance: "Unitedhealthcare" },
  { insurance: "univera Healthcare" },
  { insurance: "Independent Health" },
  { insurance: "Healthfirst (NY)" },
  { insurance: "CDPHP" },
  { insurance: "Oscar" },
];

export const Procedures = [
  { procedure: "Appendectomy" },
  { procedure: "Breast biopsy" },
  { procedure: "Blood test" },
  { procedure: "Ultrasound" },
  { procedure: "PCR nasal swab" },
  { procedure: "Skin biopsy" },
  { procedure: "Cataract surgery" },
  { procedure: "Cesarean section" },
  { procedure: "Cholecystectomy" },
  { procedure: "Coronary artery bypass" },
  { procedure: "Wound closures (stitches)" },
  { procedure: "Dilation and curettage" },
  { procedure: "Hemorrhoidectomy" },
  { procedure: "Hysterectomy" },
  { procedure: "Hysteroscopy" },
  { procedure: "Inguinal hernia repair" },
  { procedure: "Mastectomy" },
  { procedure: "MRI Scan" },
  { procedure: "Mammogram" },
  { procedure: "Tonsillectomy" },
];
